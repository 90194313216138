/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

@font-face {
  font-family: 'SuisseIntl';
  src: url('./fonts/SuisseIntl.woff2') format('woff2'),
       url('./fonts/SuisseIntl.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SuisseIntl-light';
  src: url('./fonts/SuisseIntl_light.woff2') format('woff2'),
       url('./fonts/SuisseIntl_light.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'SuisseIntl-Italic';
  src: url('./fonts/SuisseIntl-Italic.woff') format('woff'),
       url('./fonts/SuisseIntl-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.body {
  background-color: #FAFAFA;
}

.btn {
  @apply px-4 py-2 bg-blue-600 text-white font-bold rounded hover:bg-blue-500;
}
